<template>
  <div class="leader-board-group-list">
    <el-table v-bind:data="propData" border="">

      <el-table-column label="Group Name" width="300px">
        <template slot-scope="{row}">
          <span>{{ row.name }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Total LDB In Group" width="200px">
        <template slot-scope="{row}">
          <template v-if="propEditMode">
            <el-input placeholder="Please input value" v-model="row.count" @change="handleChange"></el-input>
          </template>
          <span v-else>{{ row.count }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Total Payment Users" width="200px">
        <template slot-scope="{row}">
          <template v-if="propEditMode">
            <el-input placeholder="Please input value" v-model="row.payment_size" @change="handleChange"></el-input>
          </template>
          <span v-else>{{ row.payment_size }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Total None Payment Users (Default Fill)" width="200px">
        <template slot-scope="{row}">
          <template v-if="propEditMode">
            <el-input placeholder="Please input value" v-model="row.none_payment_limit_size_1" @change="handleChange"></el-input>
          </template>
          <span v-else>{{ row.none_payment_limit_size_1 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Total Users" width="100px">
        <template slot-scope="{row}">
          <template v-if="propEditMode">
            <el-input placeholder="Please input value" v-model="row.size" @change="handleChange"></el-input>
          </template>
          <span v-else>{{ row.size }}</span>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
import { MessageBox, Message } from "element-ui";

export default {
  props: {
    propID: {
      type: Number
    },
    propData: {
      type: Array
    },
    propEditMode: {
      type: Boolean
    }
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    handleChange() {
      this.$emit("CallbackChangeData", {
        id: this.propID,
        data: this.propData
      });
    }
  }
};
</script>