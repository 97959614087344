var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leader-board-reward-list"},[_c('el-table',{attrs:{"data":_vm.propData,"border":""}},[_c('el-table-column',{attrs:{"label":"ID","width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.id))])]}}])}),_c('el-table-column',{attrs:{"label":"Title","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-input',{attrs:{"placeholder":"Please input value"},on:{"change":_vm.handleChange},model:{value:(row.title),callback:function ($$v) {_vm.$set(row, "title", $$v)},expression:"row.title"}})]:_c('span',[_vm._v(_vm._s(row.title))])]}}])}),_c('el-table-column',{attrs:{"label":"Rank Min","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-input',{attrs:{"placeholder":"Please input value"},on:{"change":_vm.handleChange},model:{value:(row.rankMin),callback:function ($$v) {_vm.$set(row, "rankMin", $$v)},expression:"row.rankMin"}})]:_c('span',[_vm._v(_vm._s(row.rankMin))])]}}])}),_c('el-table-column',{attrs:{"label":"Rank Max","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-input',{attrs:{"placeholder":"Please input value"},on:{"change":_vm.handleChange},model:{value:(row.rankMax),callback:function ($$v) {_vm.$set(row, "rankMax", $$v)},expression:"row.rankMax"}})]:_c('span',[_vm._v(_vm._s(row.rankMax))])]}}])}),_c('el-table-column',{attrs:{"label":"Required Point","width":"125px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-input',{attrs:{"placeholder":"Please input value"},on:{"change":_vm.handleChange},model:{value:(row.requiredPoint),callback:function ($$v) {_vm.$set(row, "requiredPoint", $$v)},expression:"row.requiredPoint"}})]:_c('span',[_vm._v(_vm._s(row.requiredPoint))])]}}])}),_c('el-table-column',{attrs:{"label":"Gifts","width":"1250px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-input',{attrs:{"placeholder":"Please input value"},on:{"change":_vm.handleChange},model:{value:(row.gifts),callback:function ($$v) {_vm.$set(row, "gifts", $$v)},expression:"row.gifts"}})]:_c('span',[_vm._v(_vm._s(row.gifts))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }