var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leader-board-group-list"},[_c('el-table',{attrs:{"data":_vm.propData,"border":""}},[_c('el-table-column',{attrs:{"label":"Group Name","width":"300px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"label":"Total LDB In Group","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-input',{attrs:{"placeholder":"Please input value"},on:{"change":_vm.handleChange},model:{value:(row.count),callback:function ($$v) {_vm.$set(row, "count", $$v)},expression:"row.count"}})]:_c('span',[_vm._v(_vm._s(row.count))])]}}])}),_c('el-table-column',{attrs:{"label":"Total Payment Users","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-input',{attrs:{"placeholder":"Please input value"},on:{"change":_vm.handleChange},model:{value:(row.payment_size),callback:function ($$v) {_vm.$set(row, "payment_size", $$v)},expression:"row.payment_size"}})]:_c('span',[_vm._v(_vm._s(row.payment_size))])]}}])}),_c('el-table-column',{attrs:{"label":"Total None Payment Users (Default Fill)","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-input',{attrs:{"placeholder":"Please input value"},on:{"change":_vm.handleChange},model:{value:(row.none_payment_limit_size_1),callback:function ($$v) {_vm.$set(row, "none_payment_limit_size_1", $$v)},expression:"row.none_payment_limit_size_1"}})]:_c('span',[_vm._v(_vm._s(row.none_payment_limit_size_1))])]}}])}),_c('el-table-column',{attrs:{"label":"Total Users","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-input',{attrs:{"placeholder":"Please input value"},on:{"change":_vm.handleChange},model:{value:(row.size),callback:function ($$v) {_vm.$set(row, "size", $$v)},expression:"row.size"}})]:_c('span',[_vm._v(_vm._s(row.size))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }