<template>
  <div class="leader-board-reward-list">
    <el-table v-bind:data="propData" border>

      <el-table-column label="ID" width="50px">
        <template slot-scope="{row}">
          <span>{{ row.id }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Title" width="200px">
        <template slot-scope="{row}">
          <template v-if="propEditMode">
            <el-input placeholder="Please input value" v-model="row.title" @change="handleChange"></el-input>
          </template>
          <span v-else>{{ row.title }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Rank Min" width="100px">
        <template slot-scope="{row}">
          <template v-if="propEditMode">
            <el-input placeholder="Please input value" v-model="row.rankMin" @change="handleChange"></el-input>
          </template>
          <span v-else>{{ row.rankMin }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Rank Max" width="100px">
        <template slot-scope="{row}">
          <template v-if="propEditMode">
            <el-input placeholder="Please input value" v-model="row.rankMax" @change="handleChange"></el-input>
          </template>
          <span v-else>{{ row.rankMax }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Required Point" width="125px">
        <template slot-scope="{row}">
          <template v-if="propEditMode">
            <el-input placeholder="Please input value" v-model="row.requiredPoint" @change="handleChange"></el-input>
          </template>
          <span v-else>{{ row.requiredPoint }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Gifts" width="1250px">
        <template slot-scope="{row}">
          <template v-if="propEditMode">
            <el-input placeholder="Please input value" v-model="row.gifts" @change="handleChange"></el-input>
          </template>
          <span v-else>{{ row.gifts }}</span>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
import { MessageBox, Message } from "element-ui";

export default {
  props: {
    propID: {
      type: Number
    },
    propData: {
      type: Array
    },
    propEditMode: {
      type: Boolean
    }
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    handleChange() {
      this.$emit("CallbackChangeData", {
        id: this.propID,
        data: this.propData
      });
    }
  }
};
</script>